<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">
            <!-- id, active -->
            <b-form-group
                :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                class="mb-0"
                label-class="pt-0"
                label-cols="6"
                label-size=""
            >
                <b-form-checkbox
                    id="active"
                    v-model="record.active"
                    class="float-right"
                    name="active"
                    switch
                    unchecked-value="false"
                    value="true"
                >
                    {{activeText}}
                </b-form-checkbox>
            </b-form-group>
            <!-- meta -->
            <b-form-group
                :label="$tc('form.meta.data', 0)"
                class="mb-0 group-title p-0"
                label-align-sm="left"
                label-class="font-weight-bold"
                label-cols-sm="12"
                label-size=""
            >
                <b-form-row>
                    <!-- name -->
                    <b-form-group
                        id="group-meta.name"
                        :label="$t('form.name')"
                        :state="valid_metaName"
                        class="col-sm-12 col-md-6"
                        description=""
                        label-class="mb-0"
                        label-for="meta.name"
                    >
                        <b-form-input
                            id="meta.name"
                            v-model="record.meta.name"
                            :placeholder="$t('form.required_field')"
                            :state="valid_metaName"
                            required
                        ></b-form-input>
                    </b-form-group>
                </b-form-row>
            </b-form-group>
            <!-- content -->
            <b-form-group
                :label="$t('form.content')"
                class="mb-0 group-title p-0"
                label-align-sm="left"
                label-class="font-weight-bold"
                label-cols-sm="12"
                label-size=""
            >
                <b-form-row>
                    <p>Missing stamp!!!</p>
                </b-form-row>
                <b-form-row>
                    <!-- order -->
                    <b-form-group
                        id="group-order"
                        :label="$t('form.order')"
                        class="col-sm-6 col-md-3"
                        description=""
                        label-class="mb-0"
                        label-for="order"
                    >
                        <b-form-input
                            id="order"
                            v-model="record.order"
                            type="number"
                        ></b-form-input>
                    </b-form-group>
                </b-form-row>
            </b-form-group>
            <!-- locale-->
            <b-form-group
                :label="$t('form.locale.locale')"
                class="mb-0 group-title p-0"
                label-align-sm="left"
                label-class="font-weight-bold"
                label-cols-sm="12"
                label-size=""
            >
                <b-card no-body>
                    <b-tabs card pills>
                        <b-tab v-for="locale in data.locale"
                               :active="locale.locale === $i18n.locale"
                               :title="locale.locale" style="background: white">
                            <form-partnership-type-locale
                                :locale="locale.locale"
                                :record.sync="setRecordLocale[locale.locale]"
                            ></form-partnership-type-locale>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-form-group>
            <!--  -->
        </b-form>
    </div>
</template>

<script>
import FormPartnershipTypeLocale from "@/components/register/partnership_type/FormPartnershipTypeLocale";
// @group Form.REGISTER
export default {
    name: "FormPartnershipType",
    components: {FormPartnershipTypeLocale},
    props: {
        form: {
            valid: false,
            record: {},
        },
        data: {},
        modalBus: Object,
    },
    data() {
        return {
            valid: null,
            record: {
                id: 0,
                active: false,
                order: 0,
                meta: {
                    name: '',
                },
                locale: {},
            },
        }
    },
    mounted() {
        this.modalBus.$on('submit', this.submit);
        // <--  -->
        if (this.form.record != null) {
            this.$set(this, 'record', this.form.record);
        }
    },
    methods: {
        // <-- form -->
        submit() {
            if (this.$refs.form === undefined) {
                return;
            }
            this.valid = this.$refs.form.checkValidity();

            this.$set(this.form, 'record', this.record);
            this.$set(this.form, 'valid', this.valid);
        }
    },
    computed: {
        activeText: {
            get() {
                return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                    ? this.$t('form.inactive')
                    : this.$t('form.active');
            }
        },
        // <-- locale -->
        setRecordLocale() {
            this.data.locale.forEach((value, index) => {
                // <-- add missing locale record -->
                if (Object.keys(this.record.locale).indexOf(value.locale) === -1) {
                    this.$set(this.record.locale, value.locale, {
                        name: '',
                        notes: ''
                    });
                }
            });
            return this.record.locale;
        },
        // <-- meta -->
        valid_metaName() {
            if (this.valid === null) {
                return null;
            }
            return this.record.meta.name.length > 0 ? null : false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
